h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 10vh;
  line-height: 10vh;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000;
  font-size: 38px;
  line-height: 48px;
  font-weight: 700;
}

h3 {
  margin-top: 10px;
  margin-bottom: 25px;
  color: #6b6b6b;
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
  color: #6b6b6b;
  font-size: 16px;
  line-height: 30px;
}

a {
  color: #000;
  text-decoration: underline;
}

.features-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.features-section.center {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  padding-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #111522;
  background-image: linear-gradient(135deg, #111522 56%, #5286c2 100%, #350e47);
}

.features-section.wide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-bottom: 5vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1b1b1b;
}

.features-section.wide.img {
  padding-top: 0vh;
  padding-bottom: 0vh;
  background-color: #2c2b2b;
}

.nav-bar {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding: 0px 10vw;
  border: 1px none #000;
  background-color: transparent;
}

.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.nav-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80vw;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none hsla(0, 0%, 100%, 0.1);
}

.body {
  background-color: transparent;
  font-family: Ubuntu, Helvetica, sans-serif;
}

.feature-content {
  width: 50vw;
  max-width: 450px;
  margin-left: 0px;
  padding: 0px 5vw 0px 0vw;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px none #000;
}

.feature-content.left-margin {
  padding-left: 3vw;
}

.button {
  display: inline-block;
  margin-top: 6px;
  margin-right: 0px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #5286c2;
  -webkit-transition: background-color 500ms cubic-bezier(.789, .159, .25, 1), color 200ms cubic-bezier(.789, .159, .25, 1);
  transition: background-color 500ms cubic-bezier(.789, .159, .25, 1), color 200ms cubic-bezier(.789, .159, .25, 1);
  color: #fff;
  font-size: 20px;
  line-height: 2vh;
  font-weight: 500;
  text-align: center;
}

.button:hover {
  background-color: #fff;
  color: #6b6b6b;
}

.button.form-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1), cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1), cubic-bezier(.215, .61, .355, 1);
}

.button.form-button:hover {
  background-color: #000;
  color: #fff;
}

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrap {
  max-width: 700px;
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: center;
}

.title {
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: 1px none #2e2e2e;
  color: #000;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.image-2 {
  padding-top: 5px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.nav-link {
  margin-right: 15px;
  margin-bottom: 20px;
  margin-left: 15px;
  padding-top: 40px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #5286c2;
  font-size: 16px;
}

.nav-link:hover {
  box-shadow: inset 0 -5px 0 0 #5286c2;
  color: #5286c2;
}

.hero-image {
  position: absolute;
  left: auto;
  top: 25%;
  right: 0%;
  bottom: 0%;
  width: 60%;
  height: auto;
  margin-top: 21px;
  padding-right: 30px;
  padding-left: 30px;
}

.black-text {
  color: #000;
}

.hero-content {
  position: relative;
  z-index: 90;
  width: 100%;
  padding-right: 10vh;
  padding-left: 10vw;
}

.feature-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90vw;
  max-width: none;
  min-height: auto;
  min-width: auto;
  margin-top: 5vh;
  margin-bottom: 10vh;
  padding-right: 2vw;
  padding-left: 4vw;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
}

.feature-wrap.reverse {
  margin-bottom: 5vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.feature-wrap.logos {
  height: auto;
  padding-top: 5vh;
  padding-bottom: 5vh;
  border-style: none;
}

.feature-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
}

.black {
  display: block;
  margin-left: 1px;
  color: #000;
}

.hero-subhead {
  max-width: 60%;
  margin-top: 0px;
  margin-bottom: 15px;
  color: #5286c2;
  font-size: 30px;
  line-height: 40px;
}

.hero-subhead.make {
  max-width: 50%;
  margin-top: 0px;
  margin-bottom: 18px;
  font-size: 20px;
  line-height: 30px;
}

.footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1b1b1b;
}

.footer-link {
  margin-right: 15px;
  margin-left: 15px;
  color: #6b6b6b;
  font-size: 12px;
  text-decoration: none;
}

.footer-link:hover {
  color: #fff;
}

.footer-list {
  padding-top: 29px;
  padding-bottom: 29px;
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
}

.input {
  height: 70px;
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 20px;
  clear: none;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #fff;
  box-shadow: inset 0 0 0 0 #fc0a7e;
  -webkit-transition: box-shadow 300ms cubic-bezier(.215, .61, .355, 1);
  transition: box-shadow 300ms cubic-bezier(.215, .61, .355, 1);
  color: #000;
}

.input:hover {
  box-shadow: inset 0 0 0 2px #fc0a7e;
}

.input:focus {
  box-shadow: inset 0 0 0 2px #fc0a7e;
}

.input::-webkit-input-placeholder {
  color: #555;
}

.input:-ms-input-placeholder {
  color: #555;
}

.input::-ms-input-placeholder {
  color: #555;
}

.input::placeholder {
  color: #555;
}

.arrow {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 30px;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.nav-logo-wrap {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.image-licensing {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.05);
  line-height: 24px;
}

.image-licensing-wrap {
  max-width: 600px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.heading-3 {
  color: #5286c2;
  font-size: 7vw;
}

.image-5 {
  padding-top: 10px;
}

.heading-4 {
  margin-top: 0vw;
  margin-bottom: 0vw;
}

.heading-4.first-heading-4 {
  color: #000;
}

.heading-4.white {
  color: #fff;
}

.first-hrading-4 {
  margin-top: 5vh;
}

.heading-6 {
  margin-top: 0px;
}

.cards-section {
  padding-top: 40px;
  padding-bottom: 60px;
}

.cards-section.center {
  margin-right: auto;
  margin-left: auto;
}

.centered-container {
  margin-right: auto;
  margin-left: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.cards-grid-container {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  text-align: left;
}

.cards-image-mask {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%;
  border-radius: 0%;
}

.cards-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  max-width: none;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.heading-7 {
  margin-top: 0px;
  margin-right: 10vw;
  margin-left: 10vw;
}

.cards-section-2 {
  padding-top: 0px;
  padding-bottom: 20px;
}

.centered-container-2 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-style: normal;
  text-align: center;
}

.heading-8 {
  margin-bottom: 8px;
}

.paragraph-2 {
  margin-bottom: 21px;
}

.heading-9 {
  margin-bottom: 10px;
}

.heading-10 {
  margin-bottom: 10px;
}

.heading-11 {
  margin-bottom: 10px;
}

.call-to-action {
  padding-top: 40px;
  padding-bottom: 100px;
}

.list {
  margin-right: 0px;
  padding-left: 0px;
  list-style-type: none;
}

.paragraph-3 {
  margin-right: 10vw;
  margin-left: 10vw;
  font-size: 24px;
}

.subscribe-form {
  padding-top: 10px;
  padding-bottom: 60px;
}

.subscribe-form-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.subscribe-form-input-wrapper {
  text-align: left;
}

.subscribe-form-input {
  margin-bottom: 0px;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.paragraph-4 {
  margin-right: 0vw;
  margin-left: 0vw;
}

.paragraph-5 {
  margin-top: 0px;
}

.footer-button {
  display: inline-block;
  margin-top: 0px;
  margin-right: 0px;
  padding: 1vw;
  border-radius: 10px;
  background-color: #5286c2;
  -webkit-transition: background-color 500ms cubic-bezier(.789, .159, .25, 1), color 200ms cubic-bezier(.789, .159, .25, 1);
  transition: background-color 500ms cubic-bezier(.789, .159, .25, 1), color 200ms cubic-bezier(.789, .159, .25, 1);
  color: #fff;
  font-size: 1.5vw;
  line-height: 2vh;
  font-weight: 500;
  text-align: center;
}

.footer-button:hover {
  background-color: #acc3dd;
  color: #000;
}

.footer-button.form-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1), cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1), cubic-bezier(.215, .61, .355, 1);
}

.footer-button.form-button:hover {
  background-color: #000;
  color: #fff;
}

.hero {
  display: block;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.hero-image-mask {
  width: 100%;
  margin-left: 40px;
}

.hero-image-2 {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0.5 auto;
  -ms-flex: 0 0.5 auto;
  flex: 0 0.5 auto;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.youtube {
  position: relative;
  padding-right: 0px;
}

.heading-12 {
  position: static;
  margin-top: 0px;
  margin-bottom: 10px;
}

@media screen and (min-width: 1280px) {
  .hero-image {
    top: 25%;
    width: 60%;
    height: auto;
    margin-top: 0px;
  }
  .feature-wrap {
    margin-top: 5vh;
  }
  .hero-subhead {
    margin-bottom: 10px;
  }
  .hero-subhead.make {
    font-size: 20px;
  }
  .heading-4 {
    color: #000;
  }
  .heading-4.white {
    color: #fff;
  }
  .footer-button {
    margin-top: 0px;
    padding: 1vw;
    line-height: 2vh;
  }
}

@media screen and (min-width: 1440px) {
  h2 {
    color: #000;
  }
  .features-section.wide {
    display: none;
  }
  .button {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 28px;
  }
  .hero-image {
    top: 25%;
    width: 60%;
    height: auto;
  }
  .feature-wrap {
    width: 80vw;
    margin-top: 5vh;
  }
  .heading-4.first-heading-4 {
    margin-top: 0vh;
    margin-bottom: 0vh;
    color: #000;
  }
  .heading-4.white {
    padding-top: 25px;
    color: #fff;
  }
  .heading-5 {
    color: #fff;
  }
  .heading-7 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .footer-button {
    margin-top: 0px;
    padding: 1vw;
    font-size: 1.5vw;
  }
  .heading-12 {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1920px) {
  .features-section.wide {
    margin-bottom: 0vh;
  }
  .features-section.wide.img {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .body {
    font-family: Ubuntu, Helvetica, sans-serif;
  }
  .button {
    margin-top: 0px;
    padding: 1vw;
    font-size: 1.5vw;
    line-height: 25px;
  }
  .hero-image {
    top: 25%;
    right: 0%;
    width: 60%;
    height: auto;
    margin-top: -52px;
    margin-right: 40px;
    padding-left: 54px;
  }
  .feature-wrap {
    width: 80vw;
    margin-top: 5vh;
  }
  .hero-subhead {
    margin-top: 0px;
    margin-bottom: 2vw;
    font-size: 2vw;
  }
  .hero-subhead.make {
    margin-bottom: 2vw;
    font-size: 1.5vw;
  }
  .heading-3 {
    margin-top: 0px;
    margin-bottom: 2vw;
    font-size: 6vw;
    line-height: 8vh;
    text-align: left;
    white-space: normal;
  }
  .heading-4 {
    margin-top: 0px;
    color: #000;
  }
  .heading-4.first-heading-4 {
    margin-top: 100px;
  }
  .heading-4.white {
    min-width: 200px;
    padding-top: 25px;
    color: #fff;
  }
  .paragraph {
    color: #fff;
  }
  .heading-6 {
    margin-top: 60px;
  }
  .footer-button {
    margin-top: 0px;
    padding: 1vw;
    font-size: 1.5vw;
  }
}

@media screen and (max-width: 991px) {
  h2 {
    font-size: 34px;
    line-height: 44px;
  }
  h3 {
    font-size: 20px;
    line-height: 32px;
  }
  .features-section.center {
    position: -webkit-sticky;
    position: sticky;
    height: auto;
    padding-top: 15vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .nav-bar {
    padding-right: 0vw;
    padding-left: 0vw;
  }
  .nav {
    width: 100vw;
  }
  .nav-inner {
    width: 100vw;
  }
  .button {
    margin-bottom: 30px;
  }
  .nav-link {
    padding-top: 20px;
  }
  .nav-link:hover {
    box-shadow: none;
  }
  .hero-image {
    position: absolute;
    left: auto;
    top: 23%;
    right: 4%;
    bottom: 0%;
    display: block;
    width: 50%;
    height: auto;
    margin-right: auto;
    margin-bottom: 7vw;
    margin-left: auto;
  }
  .hero-content {
    padding-right: 5vw;
    padding-left: 5vw;
  }
  .feature-wrap {
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding-right: 0vw;
    padding-left: 0vw;
  }
  .feature-wrap.logos {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .hero-subhead {
    font-size: 24px;
    line-height: 31px;
  }
  .hero-subhead.make {
    max-width: none;
  }
  .menu-icon {
    color: #fff;
    font-size: 40px;
  }
  .menu-button.w--open {
    background-color: #000;
  }
  .nav-menu-2 {
    min-width: 240px;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #000;
  }
  .brand {
    padding-left: 18px;
  }
  .heading-3 {
    font-size: 8vw;
  }
  .cards-grid-container {
    grid-column-gap: 20px;
  }
  .footer-button {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .button {
    margin-top: 10px;
  }
  .button.form-button {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 3px;
  }
  .wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: none;
    padding-right: 15vw;
    padding-left: 15vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .hero-image {
    top: 25%;
    right: 0%;
    margin-bottom: 10vw;
  }
  .hero-content {
    margin-bottom: 5vh;
  }
  .feature-wrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .hero-subhead {
    max-width: 55%;
    margin-right: 0px;
    font-size: 20px;
  }
  .hero-subhead.make {
    max-width: none;
    margin-top: 10px;
  }
  .footer-link {
    display: inline-block;
    margin-bottom: 10px;
  }
  .form-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .input {
    padding-right: 22px;
    padding-left: 22px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0px;
    text-align: center;
  }
  .menu-icon {
    font-size: 30px;
  }
  .heading-3 {
    max-width: 55%;
    min-width: 0px;
  }
  .form-block {
    width: 100%;
  }
  .heading-4.white {
    color: #fff;
  }
  .cards-section {
    padding: 40px 20px;
  }
  .cards-grid-container {
    max-width: none;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }
  .cards-section-2 {
    padding: 0px 20px;
  }
  .heading-8 {
    margin-top: 0px;
  }
  .call-to-action {
    padding: 40px 20px;
  }
  .subscribe-form {
    padding: 40px 20px;
  }
  .paragraph-4 {
    margin-right: 5vw;
    margin-left: 5vw;
  }
  .footer-button {
    margin-top: 10px;
  }
  .footer-button.form-button {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 3px;
  }
  .hero {
    padding: 40px 20px;
  }
  .flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .hero-image-mask {
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 60px;
    line-height: 70px;
  }
  h3 {
    font-size: 16px;
  }
  .features-section.center {
    padding-top: 11vh;
    padding-bottom: 0vh;
  }
  .nav-bar {
    padding-right: 0vw;
    padding-left: 0vw;
  }
  .nav-inner {
    width: 100vw;
  }
  .feature-content {
    width: auto;
  }
  .button {
    display: block;
    max-width: 60%;
    margin: 10px auto 0px;
    font-size: 14px;
    line-height: 20px;
  }
  .wrap {
    padding-right: 5vw;
    padding-left: 5vw;
  }
  .title {
    padding-bottom: 0px;
  }
  .image-2 {
    width: 100%;
  }
  .hero-image {
    position: absolute;
    left: 0%;
    top: 10%;
    right: 0%;
    bottom: auto;
    width: 90%;
    margin-top: 5vw;
    margin-bottom: 5vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .hero-content {
    margin-top: 24px;
  }
  .feature-wrap {
    margin-top: 3vh;
    margin-bottom: 0vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .feature-wrap.reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .feature-image {
    width: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .hero-subhead {
    max-width: 80%;
    margin: 20px auto 10px;
    padding-top: 0px;
    font-size: 18px;
    text-align: center;
  }
  .hero-subhead.make {
    position: static;
    text-align: center;
  }
  .form-2 {
    width: 90vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .arrow {
    margin-top: 0px;
    padding-top: 0px;
  }
  .heading-3 {
    position: static;
    margin: 150px auto -22px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 36px;
    line-height: 56px;
    text-align: center;
  }
  .heading-4 {
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
  }
  .heading-6 {
    margin-bottom: 0px;
  }
  .centered-container {
    text-align: left;
  }
  .cards-grid-container {
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 20px;
    padding-bottom: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .heading-7 {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  .centered-container-2 {
    text-align: left;
  }
  .paragraph-3 {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  .subscribe-form-input-wrapper {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .subscribe-form-input {
    width: 100%;
  }
  .paragraph-4 {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  .footer-button {
    display: block;
    max-width: 60%;
    margin: 10px auto 0px;
    font-size: 14px;
    line-height: 20px;
  }
  .heading-12 {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  #w-node-54a11a171517-089389fc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-54a11a171519-089389fc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-54a11a17151e-089389fc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-54a11a171520-089389fc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row-align: start;
    align-self: start;
  }
  #w-node-fe4abcc5247c-089389fc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-fe4abcc52483-089389fc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row-align: start;
    align-self: start;
  }
}

@media screen and (max-width: 479px) {
  #w-node-54a11a17150d-089389fc {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-54a11a171514-089389fc {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-54a11a17151b-089389fc {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-fe4abcc52470-089389fc {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-fe4abcc52477-089389fc {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-fe4abcc5247e-089389fc {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

